.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 10vh;
    margin: 8px;
    padding: 8px;
    width: 200px;
    text-align: left;
    
}
.footer-column a {
    color: grey;
}

  
  .main-title {
    font-size: 1.7em;
    font-weight: bold;
    vertical-align: bottom;
    line-height: 25px;
    margin-bottom: 16px;
    
  }
  
  .secondary-title {
    font-size: 1.2em;
    font-weight: bold;
    vertical-align: bottom;
    line-height: 25px;
    margin-bottom: 16px;
    padding-left: 4px;
  }

  .footer-item-link {
      text-decoration: none;
      color: black;
      margin-bottom: 4px;
      width: 100%;
      padding: 4px;
  }

  .footer-item-link:hover {
      cursor: pointer;
      background: rgb(245, 241, 241);
  }