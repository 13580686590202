.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-header {
  background-color: #282c34;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding: 1rem;
}

.app-title {
  font-size: 1.4rem;
  font-weight: bold;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background: #e96443;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #904e95, #e96443);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #904e95, #e96443); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.warning {
  background: #6bff699e;
  padding: 1rem;
}